.accordion__title {
  font-size: 14px;
  text-align: center;
  color: #F74481;
  width: 100%;
  display: block;
  position: relative;
  padding: 16px;
  background-color: #FFF5F5;
  margin-bottom: 16px;
}
.accordion__toggle {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  &.accordion__toggle::before, 
  &.accordion__toggle::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 24px;
    height: 3px;
    background-color: #F74481;
  }
  &.accordion__toggle::after {
    transform: translate(50%, -50%) rotate(90deg);
    display: block;
  }
  &.accordion__toggle__open::after {
    display: none;
  }
}

.accordion__inner_open {
  display: block;
}

.accordion__inner_close {
  display: none;
}