.wrap {
  background-color: #fff;
  border-radius: 4px;
  padding: 24px 16px ;
}
.inputLabel {
  font-weight: 400;
  font-size: 14px;
}
.inputWrap {
  margin-top: 8px;
}
.cardList {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.cardList__img {
  width: 50px;
}
.cardList__item {
  display: flex;
  flex-direction: column;
  align-items: center;

}
.cardList__text {
  font-size: 10px;
  margin-top: 12px;
}