.checkbox__wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 16px;
  &.checkbox__wrapper:last-child {
    margin-bottom: 0;
  }
}
.checkbox__title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}
.checkbox__title__badgeIsEdge {
  justify-content: space-between;
}
.checkbox__title__border {
  padding-bottom: 8px;
  border-bottom: 1px solid #dEDeDE;
}
.checkbox__titleText {
  flex-shrink: 1;

}
.checkbox__titleBadge {
  flex: 0 0 40px;
}
.checkbox__text {
  width: 100%;
  text-align: left;
  font-size: 14px;
}
.checkbox__label {
  position: relative;
  border: 1px solid #333;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  margin-left: 8px;
  &.checkbox__label::before {
    content: '';
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 20px;
    height: 10px;
    background-color: transparent;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    display: none;
  }
}
.checkbox__input {
  &.checkbox__input:checked {
    & + .checkbox__label {
      background-color: #F74481;
      &.checkbox__label::before {
        display: block;
      }
    }
  }
}