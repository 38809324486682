
.heart_wrapper {
  position: absolute;
  top: 46%; /* 中心よりちょい上の方が綺麗に見えるので */
  left: 50%;
  transform: translate(-50%, -50%); 
}

.heart {
  width: 64px;
  height: 64px;
  position: relative;
  animation-name: heart-beat;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  z-index: 100;
}
.heart::before,
.heart::after {
  content: "";
  width: 50%;
  height: 80%;
  border-radius: 25px 25px 0 0;
  display: block;
  position: absolute;
}
.heart::before {
  background: linear-gradient(40deg, rgba(255,130,173,1) 0%, rgba(247,68,129,1) 39%, rgba(247,68,129,1) 53%, rgba(255,133,175,1) 100%);
  transform: rotate(-45deg);
  left: 14%;
}
.heart::after {
  background: linear-gradient(-40deg, rgba(255,130,173,1) 0%, rgba(247,68,129,1) 39%, rgba(247,68,129,1) 53%, rgba(255,133,175,1) 100%);
  transform: rotate(45deg);
  right: 14%;
}






@keyframes heart-beat {
  0% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  14% {
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  28% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  42% {
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}