.wrap {
  display: flex;
  min-height: 100px;
  border-radius: 8px;
}
.wrap__female {
  min-height: 88px;
}

.month {
  font-size: 20px;
}
.monthArea {
  flex: 0 0 111px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.monthGain {
  background-color: #FFE500;
  border-radius: 20px;
  min-width: 88px;
  padding: 0 4px;
  text-align: center;
  font-size: 14px;
}

.priceArea {
  background-color: #fff;
  padding-right: 32px;
  border-radius:  0px 4px 4px 0 ;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;

}

.priceAreaMain {
  display: flex;
  align-items: center;
}

.priceAreaMainPrice {
  font-size: 34px;
  font-weight: bold;

}

.priceUnit {
  display: flex;
  justify-content: right;
  align-items: right;
  flex-direction: column-reverse;
  margin-left: 2px;
}