.radio__wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 16px;
  &.radio__wrapper:last-child {
    margin-bottom: 0;
  }
}
.radio__title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}
.radio__title__badgeIsEdge {
  justify-content: space-between;
}
.radio__title__border {
  padding-bottom: 8px;
  border-bottom: 1px solid #dEDeDE;
}
.radio__titleText {
  flex-shrink: 1;

}
.radio__titleBadge {
  flex: 0 0 40px;
}
.radio__text {
  width: 100%;
  text-align: left;
  font-size: 14px;
}
.radio__label {
  position: relative;
  border: 1px solid #333;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  margin-left: 8px;
  &.radio__label::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: #fff;
  }
}
.radio__input {
  &.radio__input:checked {
    & + .radio__label {
      border-color: #F74481;
      &.radio__label::before {
        background-color: #F74481;
      }
    }
  }
}

.radioSex__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.radioSex__labelWrapper {
  width: 100%;
  margin-right: 19px;
  &.radioSex__labelWrapper:last-child {
    margin-right: 0;
  }
}
.radioSex__label  {
  background-color: #fff;
  border: 4px solid #87D7F5;
  text-align: center;
  cursor: pointer;
  font-weight: medium;
  padding-top: 32px;
  font-size: 20px;
  padding-bottom: 40px;
  border-radius: 4px;
  &.radioSex__label__female {
    border-color: #FF89B1;
  }
}
.radioSex__input {
  &.radioSex__input:checked {
    + .radioSex__label {
      background-color: #E2F7FF;
      &.radioSex__label__female {
        background-color: #FFD3E2;
      }
    }
  }
}

.radioAppear__wrapper {
  border-radius: 4px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 12px;
  .radioAppear__text {
    width: 100%;
    display: block;
    border-radius: 4px;
    background-color: #F0F0F0;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  &.radioAppear__wrapper:last-child {
    margin-bottom: 0;
  }
}

.radioAppear__input {
  &.radioAppear__input:checked {
    + .radioAppear__text {
      background-color: #F74481;
      color: #fff;
    }
  }
}
